/**
 * @module PreampFallback
 * @description Min-height fallback for Preamp
 */

import styles from './index.module.css';

export const Component = ( { height = [] } ) => (
  <div style={ {
    '--mobile': height[ 0 ],
    '--tablet': height[ 1 ],
    '--desktop': height[ 2 ]
  } }
    className={ styles.fallback }
  />
);

export default Component;
