/**
 * @module Accordion
 * @description Our Accordion JS file
 */
import { slideUp, slideDown } from '@/js/utils/dom/slide';

/**
 * @class Accordion
 * @description Create an accordion instance
 */
class Accordion {
  constructor( el ) {
    this.accordion = el;
    this.initAccordion = el.hasAttribute( 'data-accordion-init' ) ? el.getAttribute( 'data-accordion-init' ) : null;
    this.accordionToggle = el.hasAttribute( 'data-accordion-toggle' ) ? ( el.getAttribute( 'data-accordion-toggle' ) === 'true' ) : false;

    this.init();
  }

  /**
	 * @function collapse
	 * @description Collapse the accordion
	 */
  collapse( item ) {
    item.classList.remove( '--active' );

    const btn = item.querySelector( 'button' );
    btn.setAttribute( 'aria-expanded', false );

    // Hide the accordion content
    const content = item.querySelector( '.accordion-content' );
    slideUp( content );
  }

  /**
	 * @function collapseSibling
	 * @description Collapse the sibling accordions
	 */
  collapseSiblings( item ) {
    const index = item.getAttribute( 'data-index' );
    const siblings = this.accordion.querySelectorAll( '.accordion-item' );
    const siblingAccordions = Array.prototype.slice.call( siblings ).filter( ( sibling ) => sibling.getAttribute( 'data-index' ) !== index );

    siblingAccordions.forEach( ( accordion ) => {
      this.collapse( accordion );
    } );
  }

  /**
	 * @function expand
	 * @description Expand the accordion
	 */
  expand( item ) {
    const btn = item.querySelector( 'button' );

    if ( ! btn ) {
      return;
    }

    if ( btn.hasAttribute( 'aria-disabled' ) ) {
      return;
    }

    item.classList.add( '--active' );

    if ( this.accordionToggle === true ) {
      this.collapseSiblings( item );
    }

    btn.setAttribute( 'aria-expanded', true );

    // Show the accordion content
    const content = item.querySelector( '.accordion-content' );
    slideDown( content );
  }

  /**
	 * @function toggle
	 * @description Toggle our accordions
	 */
  toggle( event ) {
    const { target } = event;
    let item = target;

    // Ignore clicks within accordion content
    if ( target.tagName === 'A' ) {
      return;
    }

    if ( target.tagName === 'BUTTON' ) {
      item = target.parentNode;
    }

    if ( item.classList.contains( '--active' ) ) {
      this.collapse( item );
    } else {
      this.expand( item );
    }
  }

  /**
	 * @function setInitialState
	 * @description Set the initial state of the accordion
	 */
  setInitialState() {
    if ( this.initAccordion === null ) {
      return;
    }

    const item = this.accordion.querySelector( `.accordion-item[data-index="${ this.initAccordion }"]` );
    this.expand( item );
  }

  /**
	 * @function bindEvents
	 * @description Bind events to this class
	 */
  bindEvents( item ) {
    item.addEventListener( 'click', this.toggle.bind( this ) );
  }

  /**
	 * @function init
	 * @description Kick off this classes functions
	 */
  init() {
    const items = this.accordion.querySelectorAll( '.accordion-item' );

    if ( ! items.length ) {
      return;
    }

    items.forEach( ( item ) => {
      this.bindEvents( item );
    } );

    this.setInitialState();
  }
}

export default Accordion;
