/**
 * @module Slide Utility
 * @description jQuery's slideDown function - uses CSS3 transitions
 */

/**
 * @function slideDown
 * @description Like jQuery's slideDown function - uses CSS3 transitions
 * @param {Node} elem Element to show and hide
 */
export const slideDown = ( el ) => {
  el.style.maxHeight = '100%';
  el.style.opacity = '1';
};


/**
 * @function slideUp
 * @description Slide element up (like jQuery's slideUp)
 * @param {Node} elem Element
 * @return {[type]} [description]
 */
export const slideUp = ( el ) => {
  el.style.maxHeight = '0';
  el.style.opacity = '0';
};
