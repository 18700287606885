/**
 * @module Adobe
 * @description Our Adobe Snippet
 */
import Script from 'next/script';

/**
 * @function AdobeSnippet
 * @description Adobe Snippet
 */
export const AdobeSnippet = () => (
  <Script
    strategy="lazyOnload"
    src="https://assets.adobedtm.com/fc19531c0bf6205e4c8c1a357c620866eec50643/satelliteLib-4b8641237ab881231ea9edcc135d0fdd111e2a40.js"
    onLoad={ () => {
      const { pathname } = window.location;
      let path = pathname.length > 1 ? pathname.substring( 1 ) : pathname;

      if ( path === '/' ) {
        path = 'home';
      }

      s_dtm.trackData( {
        pageName: `ftr:${ path }`,
        eVar22: 'visitor',
        eVar49: 'unverified service area'
      } );
    } }
  />
);

export default AdobeSnippet;
