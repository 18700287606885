/**
 * @module Site Legal
 * @description Footer Site Legal
 */
import { useContext } from 'react';
import { ContentProviderContext } from '../index';

/**
 * @function Component
 * @description
 */
const Component = () => {
  const { legal } = useContext( ContentProviderContext );

  return (
    <>
      <div id="site-legal" className="site-legal legal">
        { legal && [ ...legal ].map( ( item, index ) => (
            <p
              key={ index }
              id={ `legal-${ item.annotationIndex }` }
              data-hash={ item.hash }
              className="legal-text"
              dangerouslySetInnerHTML={ {
                __html: `[${ item.annotationIndex }] ${ item.full }`
              } } />
        ) ) }
      </div>
    </>
  );
};

export default Component;
