/**
 * @module CookieConsent
 * @description Our cookie consent script
 */
import Script from 'next/script';

/**
 * @function CookieConsent
 * @description Cookie Consent script
 */
export const CookieConsent = () => (
  <>
    <span
      dangerouslySetInnerHTML={ {
        __html: '<!-- OneTrust Cookies Consent Notice start for frontier.com -->'
      } }
    />
    <Script
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      type="text/javascript"
      data-domain-script="f2982948-7112-43c4-834a-191657168573"
    />
    <script
      type="text/javascript"
      dangerouslySetInnerHTML={ {
        __html: 'function OptanonWrapper() { }'
      } }
    />
    <span
      dangerouslySetInnerHTML={ {
        __html: '<!-- OneTrust Cookies Consent Notice end for frontier.com -->'
      } }
    />
  </>
);

export default CookieConsent;
