/**
 * @module Profile Hooks
 * @description
 */
import { deleteCookie } from '@/js/utils/cookie';
import { getCookie } from '@/js/utils/cookie';

/**
 * @function useProfile
 * @description Our profile API
 */
export const useProfile = () => {
  /**
   * @function logout
   * @description Log the user out
   */
  const logout = async () => {
    const domain = window.location.origin;
    const logoutURL = new URL( `${ domain }/api/logout` );

    // Local testing
    if ( process.env.environment === 'local' || window.location.host === 'ftrmarketingsandbox.z19.web.core.windows.net' || window.location.host === 'ftrmarketingnonprod.z19.web.core.windows.net' ) {
      deleteCookie( 'frontieramp' );
    }

    try {
      await fetch( logoutURL ).then( () => {
        window.location.reload();
      } );
    } catch ( error ) {
      console.error( error );
    }
  };

  /**
   * @function trimName
   * @description Trim our users name
   */
  const trimName = ( name ) => {
    if ( name.length <= 11 ) {
      return name;
    }

    const trimmed = `${ name.substring( 0, 11 ) }...`;

    return trimmed;
  };

  /**
   * @function fetchProfile
   * @description Fetch the users profile
   */
  const fetchProfile = async () => {
    // Local testing
    // Bail if we don't have a cookie
    if ( ! getCookie( 'frontieramp' ) ) {
      return;
    }

    // If we have the cookie but we are local or sandbox, fake the data and return.
    if ( process.env.environment === 'local' || window.location.host === 'ftrmarketingsandbox.z19.web.core.windows.net' || window.location.host === 'ftrmarketingnonprod.z19.web.core.windows.net' ) {
      const firstName = 'Mahendra';
      return {
        uid: 'ff8dbbb480cf4230b6a43304c4cefbcd',
        firstName: trimName( firstName ),
        lastName: 'Test'
      };
    }

    // This should be fired if we are on QAT02 or Production
    const domain = window.location.origin;
    const profileURL = new URL( `${ domain }/api/profile` );

    // Fetch profile data if we don't already have it.
    const profileData = await fetch( profileURL )
      .then( ( res ) => res.json() )
      .then( ( data ) => {
        if ( ! data ) {
          return null;
        }

        const details = {
          uid: data?.uid,
          firstName: trimName( data?.firstName ),
          lastName: data?.lastName
        };

        return details;
      } );

    return profileData;
  };

  return { fetchProfile, logout };
};

export default useProfile;
