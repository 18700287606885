/**
 * @module Google Tag Manage
 * @description Our GTM Snippet
 */
import Script from 'next/script';

/**
 * @function GTMSnippet
 * @description Google Tag Manager Snippet
 */
export const GTMScript = () => (
  <Script
    defer
    strategy="lazyOnload"
    src="https://www.googletagmanager.com/gtm.js?id=GTM-W8MX7L5"
  />
);

/**
 * @function GTMSnippet
 * @description Google Tag Manager Snippet
 */
export const GTMSnippet = () => (
  <Script id="gtm-snippet" defer>
    { `(function (w, d, s, l, i) {
          w[l] = w[l] || []; w[l].push({
              'gtm.start':
                  new Date().getTime(), event: 'gtm.js'
          }); var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                  'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-W8MX7L5');` }
  </Script>
);
