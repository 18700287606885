/**
 * @module Content Provider Hooks - Legal
 * @description
 */
import {
  useRef, useCallback, useContext
} from 'react';
import { ContentProviderContext } from '../index';
import { filterLegal, setAnnotationIndex, insertAnnotations } from '../utils/legal';

/**
 * @function useContentProviderLegal
 * @description Locate all legal hash keys on site and begin setup.
 */
const useContentProviderLegal = () => {
  const { disclosures, setLegal } = useContext( ContentProviderContext );
  const ref = useRef( null );

  const setRef = useCallback( ( node ) => {
    cohesion( 'ready', () => {
      if ( node ) {
        setTimeout( () => {
          const { legal, legalNodes } = filterLegal( node, disclosures );
          setAnnotationIndex( legal, setLegal );
          insertAnnotations( legal, legalNodes );
        }, 1500 );
      }

      ref.current = node;
    } );
  }, [ ref, disclosures, setLegal ] );

  return [ setRef ];
};

export default useContentProviderLegal;
