/**
 * @module Schema Markup
 * @description
 */
const SchemaMarkup = () => (
  <script
    type="application/ld+json"
    dangerouslySetInnerHTML={ {
      __html:
      `{
          "@context": "https://schema.org",
          "@type": "Corporation",
          "name": "Frontier",
          "alternateName": "Frontier Communications",
          "url": "https://frontier.com",
          "logo": "https://frontier.com/helpcenter/~/media/HelpCenter/Images/customer-types-images/2021_frontier-red-header.ashx",
          "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+1 (800) 921-8101",
          "contactType": "customer service",
          "areaServed": "US",
          "availableLanguage": ["en","es"]
          },
          "sameAs": [
          "https://www.facebook.com/FrontierCorp",
          "https://twitter.com/FrontierCorp",
          "https://www.linkedin.com/company/frontier-communications",
          "https://www.youtube.com/user/FrontierCorp",
          "https://en.wikipedia.org/wiki/Frontier_Communications"
          ]
      }`
    } }
  />
);

export default SchemaMarkup;
